<template>
    <div class="rb-view">
        <div>
            <div>
                <el-form :ref="search_form_name" :model="search_option" size="mini" inline label-width="88px">
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="入账时间:" prop="time_range">
                                <el-radio-group v-model="search_option.record_time_range" @change="handleSearchTap">
                                    <el-radio-button label="all">全部</el-radio-button>
                                    <el-radio-button label="today">今日</el-radio-button>
                                    <el-radio-button label="yesterday">昨天</el-radio-button>
                                    <el-radio-button label="7day">7天内</el-radio-button>
                                    <el-radio-button label="30day">30天内</el-radio-button>
                                    <el-radio-button label="this_month">今月</el-radio-button>
                                    <el-radio-button label="this_year">今年</el-radio-button>
                                </el-radio-group>
                                <span>
                                    <span style="margin-left:20px;margin-right:10px;">自定义:</span>
                                      <el-date-picker @change="handleSearchTap"
                                                      v-model="search_option.record_time_range"
                                                      type="daterange"
                                                      range-separator="至"
                                                      start-placeholder="开始日期"
                                                      end-placeholder="结束日期">
                                      </el-date-picker>
                                </span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="下单时间:" prop="time_range">
                                <el-radio-group v-model="search_option.order_time_range" @change="handleSearchTap">
                                    <el-radio-button label="all">全部</el-radio-button>
                                    <el-radio-button label="today">今日</el-radio-button>
                                    <el-radio-button label="yesterday">昨天</el-radio-button>
                                    <el-radio-button label="7day">7天内</el-radio-button>
                                    <el-radio-button label="30day">30天内</el-radio-button>
                                    <el-radio-button label="this_month">今月</el-radio-button>
                                    <el-radio-button label="this_year">今年</el-radio-button>
                                </el-radio-group>
                                <span>
                                    <span style="margin-left:20px;margin-right:10px;">自定义:</span>
                                      <el-date-picker @change="handleSearchTap"
                                                      v-model="search_option.order_time_range"
                                                      type="daterange"
                                                      range-separator="至"
                                                      start-placeholder="开始日期"
                                                      end-placeholder="结束日期">
                                      </el-date-picker>
                                </span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="收支:" prop="record_flag">
                                <el-radio-group v-model="search_option.record_flag" @change="handleSearchTap">
                                    <el-radio-button label="all">全部</el-radio-button>
                                    <el-radio-button label="income">收入</el-radio-button>
                                    <el-radio-button label="outcome">支出</el-radio-button>
                                </el-radio-group>
                            </el-form-item>
<!--                            <el-form-item label="类型:" prop="record_type">-->
<!--                                <el-select v-model="search_option.record_type" clearable>-->
<!--                                    <el-option v-for="p in para_record_type" :label="p.para_value" :key="p.para_key" :value="p.para_key"></el-option>-->
<!--                                </el-select>-->
<!--                            </el-form-item>-->
                            <el-form-item label="订单号:" prop="order_id"><el-input v-model="search_option.order_id" clearable></el-input></el-form-item>
                            <el-form-item label="商户流水号:" prop="transaction_id"><el-input v-model="search_option.transaction_id" clearable></el-input></el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <span style="white-space: nowrap">
                              <el-button type="primary" icon="el-icon-search" size="mini" style="margin-left: 20px;" @click="handleSearchTap">查询</el-button>
                              <el-button icon="el-icon-delete" size="mini" @click="handleSearchClear(search_form_name)">清空</el-button>
                            </span>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div style="margin-top: 16px;">
                <el-table :data="page_list" size="mini" border>
                    <el-table-column label="入账时间" prop="record_at"  width="136" :formatter="elTimeStampFormat"></el-table-column>
                    <el-table-column label="id" width="150" prop="id"></el-table-column>
                    <el-table-column label="收支" prop="record_flag" width="56">
                        <template #default="scope">
                            <span :style="{color:params_map['balance_record_flag']?params_map['balance_record_flag'][scope.row.record_flag].color:''}">{{params_map['balance_record_flag']?params_map['balance_record_flag'][scope.row.record_flag].value:''}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="类型" prop="record_type" width="88" show-tooltip-when-overflow show-overflow-tooltip>
                        <template #default="scope">
                            <span :style="{color:params_map['balance_record_type']?params_map['balance_record_type'][scope.row.record_type].color:''}">{{params_map['balance_record_type']?params_map['balance_record_type'][scope.row.record_type].value:''}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="名称" prop="record_name" show-overflow-tooltip></el-table-column>
                    <el-table-column label="支付流水号" prop="transaction_id" width="210"></el-table-column>
                    <el-table-column label="关联订单号" prop="order_id" width="150"></el-table-column>
                    <el-table-column label="收入" prop="income" align="right"></el-table-column>
                    <el-table-column label="支出" prop="outcome" align="right"></el-table-column>
                    <el-table-column label="变化金额" prop="change_balance" align="right">
                        <template #default="scope">
                            <span v-if="scope.row.change_balance>0" style="color:#409EFF;font-weight: bold;">{{scope.row.change_balance}}</span>
                            <span v-if="scope.row.change_balance<0" style="color:#F56C6C;font-weight: bold;">{{scope.row.change_balance}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="当月结余" prop="after_balance" align="right">
                        <template #default="scope">
                            <span style="font-weight: bold">{{scope.row.after_balance}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="总结余" prop="after_total_balance" align="right">
                        <template #default="scope">
                            <span style="font-weight: bold">{{scope.row.after_total_balance}}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="margin-top:20px;margin-bottom: 0px; text-align:right;">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="page_option.page"
                            :page-sizes="[5,10, 20, 30, 50,100,200]"
                            :page-size="page_option.size"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="page_option.total"
                    ></el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import{getParams} from "@/api/hado/param";
    import {getList} from "@/api/hado/balance-record";
    import {formatDate, formatTimestamp} from "@/utils/time";
    const param_list=['balance_record_flag','balance_record_type','balance_bill_cycle_type']
    export default {
        name: "balance-record-list",
        data(){
            return{
                loading:false,
                search_form_name:'balance_record_search',
                page_option:{page:1,size:30,total:0},
                search_option:{time_range:'all',play_type:'all'},
                page_list:[],
                params:{playground_type:[],},
                params_map_pure:{},
                params_map:{recharge_type:{},},
            }
        },
        created(){
            this.initPage()
        },
        methods:{
            initPage() {
                this.initParams().then(() => {
                    this.getPageList({page: 1, size: this.page_option.size}, Object.assign({}, this.search_option))
                })
            },
            reloadPage() {
                this.getPageList(this.page_option, this.search_option)
            },
            initParams() {
                return new Promise((resolve, reject) => {
                    getParams(param_list.join(','), 'en').then(resp => {
                        this.params = resp.data;

                        let tmp_params_map = {}
                        let tmp_params_map_pure = {}
                        let tmp_params_map_reverse = {}

                        //这里再这里一下每组参数做成kv方便显示
                        let p_keys = Object.keys(this.params)
                        p_keys.forEach(pl => {
                            console.log('pl:', pl)
                            let tmp_map = {}
                            let tmp_map_pure = {}
                            let tmp_map_reverse = {}
                            this.params[pl].forEach(pm => {
                                // tmp_map[pm.key]=pm.value
                                tmp_map_pure[pm.key] = pm.value
                                tmp_map[pm.key] = {key: pm.key, value: pm.value, color: pm.color}

                                //再调转处理一下 导入Excel参数解析用
                                tmp_map_reverse[pm.value] = pm.key
                            })
                            tmp_params_map[pl] = tmp_map
                            tmp_params_map_pure[pl] = tmp_map_pure
                            tmp_params_map_reverse[pl] = tmp_map_reverse
                        })

                        this.params_map = tmp_params_map
                        this.params_map_pure = tmp_params_map_pure
                        this.params_map_reverse = tmp_params_map_reverse
                        resolve(resp)
                    }).catch((err) => {
                        if (err) {
                            console.error(err)
                        }
                        reject(err)
                    })
                })
            },
            getPageList(pageOpt, searchOpt) {
                this.loading = true;
                getList(pageOpt.page, pageOpt.size, searchOpt).then(resp => {
                    this.loading = false;
                    this.page_list = resp.data.records;
                    this.page_option.page = pageOpt.page;
                    this.page_option.total = resp.data.total;
                    this.page_option.size = pageOpt.size;
                }).catch(() => {
                    this.loading = false;
                })
            },
            handleSearchTap() {
                let searchOpt = Object.assign({}, this.search_option)
                console.log(searchOpt.time_range)
                if(this.search_option.record_time_range&&Array.isArray( this.search_option.record_time_range)){
                    searchOpt.record_time_range = formatDate(searchOpt.record_time_range[0])+'||'+formatDate( searchOpt.record_time_range[1])
                }
                if(this.search_option.order_time_range&&Array.isArray( this.search_option.order_time_range)){
                    searchOpt.order_time_range = formatDate(searchOpt.order_time_range[0])+'||'+formatDate( searchOpt.order_time_range[1])
                }
                this.getPageList({page: 1, size: this.page_option.size}, searchOpt)
            },
            handleSearchClear(formName) {
                this.$refs[formName].resetFields();
                this.initPage()
            },
            handleSizeChange(pageSize) {
                this.getPageList({page: 1, size: pageSize}, this.search_option);
            },
            handleCurrentChange(pageIndex) {
                this.getPageList({page: pageIndex, size: this.page_option.size}, this.search_option)
            },
            elTimeStampFormat(row,column,val){
                return formatTimestamp(val)
            },
        }
    }
</script>

<style scoped>

</style>
